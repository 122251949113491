<template>
  <div class="container-fluid">
    <div class="row row--grid">
      <!-- breadcrumb -->
      <div class="col-12">
        <ul class="breadcrumb">
          <li class="breadcrumb__item"><router-link to="/ja">ホーム</router-link></li>
          <li class="breadcrumb__item breadcrumb__item--active">紹介</li>
        </ul>
      </div>
      <!-- end breadcrumb -->

      <!-- title -->
      <div class="col-12">
        <div class="main__title">
          <h2>AI Teadingをご紹介します。</h2>
          <p>
            人工知能は未来のイノベーションをもたらす技術の一つです。
            <br/>
            AI Teadingは<b>人工知能技術を手軽に体験できるサイト</b>です。
            <br/>
            このサイトでは、オープンソースで公開されている人工知能アプリをサイト内で利用したり、リンク先の外部サイトで体験することができます。
            <br/>
            様々な分野の人工知能技術を体験することができ、人工知能の未来に興味がある人にも有益なサイトです。
            <br/>
            AI Teadingで人工知能の驚きと活用の可能性を感じてみてください！
          </p>
        </div>
      </div>
      <!-- end title -->

      <div class="col-12 col-lg-4">
        <div class="step">
          <span class="step__number">01</span>
          <h3 class="step__title">簡単に体験できます</h3>
          <p class="step__text">ほとんどのアプリは特別な説明は必要なく、クリック数回で体験することができます。任意のアプリをクリックして人工知能について調べてみてください。</p>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="step">
          <span class="step__number">02</span>
          <h3 class="step__title">様々な種類があります</h3>
          <p class="step__text">一度だけ体験しても時間がかかるほど様々なアプリがあります。興味のある分野を中心に様々な人工知能アプリを試してみてください。</p>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="step">
          <span class="step__number">03</span>
          <h3 class="step__title">よく分類されています</h3>
          <p class="step__text">検索エリアやサイドバーを利用すれば、興味のある分野のアプリを簡単に見つけることができます。 特定の分野のアプリを分類機能を利用して探してみてください。</p>
        </div>
      </div>
    </div>

    <div class="row row--grid">
      <!-- title -->
      <div class="col-12">
        <div class="main__title">
          <h2>AI Teadingのよくある質問</h2>
          <p>AI Teadingについて気になる点をまとめてみました。 その他、ご不明な点がございましたら、下記メールアドレスまでお問い合わせください。</p>
        </div>
      </div>

      <div class="col-12">
        <a href="mailto:codingteading@gmail.com" class="main__load my-2" style='width: 100%; max-width: 250px'>codingteading@gmail.com</a>
      </div>
      <!-- end title -->

      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">追加料金はかかりますか？</h3>
          <p class="feature__text">AI Teadingに含まれるすべてのアプリは無料です。料金支払いの心配なく、すべての機能をご利用いただけます。ただし、外部サイトの場合、そのサイトの運営方法によっては料金決済が発生する場合があります。</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">アプリを外部に公開してもいいですか？</h3>
          <p class="feature__text">サイトに含まれるアプリを外部に公開していただければ幸いです。商業的、非商業的な目的であれば、自由に公開して利用することができます。オープンソースのアプリであれば、コピーして変形して利用することも可能です。</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">サイトはどのように制作されましたか？</h3>
          <p class="feature__text">オープンソースで公開され、ウェブ上で動作する人工知能アプリを長い時間をかけて探しました。 選択したアプリを取り出し、リスト化、カテゴリ化しました。ライセンスを守るためにソースを公開し、可能な範囲内で変形しました。</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">動作しないアプリはどうすればいいですか？</h3>
          <p class="feature__text">サイトに含まれるアプリは、PCのChromeブラウザでテストされました。モバイルより性能が良いPCでクロムベースのブラウザを使用してください。それでも動作しない場合は、メールでお問い合わせください。</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">サイト制作の目的は何ですか？</h3>
          <p class="feature__text">人工知能が急速に発展し、公開された様々なアプリがあるにもかかわらず、簡単にアクセスしにくいという課題がありました。誰でも簡単に人工知能を体験できる環境を作ろうという趣旨で制作しました。</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">改善提案はどうすればいいですか？</h3>
          <p class="feature__text">コーディングティディングでまだ見つけられなかった人工知能アプリがあるかもしれません。誤った分類や使いやすさを改善するための良い方法もあるでしょう。AI Teadingの発展に貢献したい方は、メールで提案してください。</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "JA_About",
  data: function() {
    return {
    };
  },
  created() {
    $('html, body').animate({
      scrollTop: 0
    }, 250);
  }
}
</script>
